import React from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Checkbox, Typography, Link, Grid } from "../../../components/mui";
import { StyledCircularProgress } from "../../../components/styled/StyledCircularProgress";
import Title from "../../../components/Title";
import { postRegister, setForm } from "../../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../../store/institution/institution.slice";
import { setLoans } from "../../../store/loans/loans.slice";
import { constructRegisterPayload } from "../../../utils/helpers/constructRegisterPayload";
import { useMultipleFormErrors } from "../../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../../utils/validators/formHookSchema";
import AccountDetails from "../components/AccountDetails";
import UserInfo from "../components/UserInfo";

const Validation = () => {
  // * Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // * Use named selectors
  const { config } = useSelector(institutionState);
  const { register: registerState, form: registerForm } = useSelector(authState);

  const member_id = config?.services?.registered?.fields?.member_id;

  // * Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors: formErrors }, control } = useForm({
    mode: "onBlur",
    defaultValues: {
      firstName: registerForm?.firstName || "",
      lastName: registerForm?.lastName || "",
      emailAddress: registerForm?.emailAddress || "",
      memberId: registerForm?.memberId || "",
      securityAnswer1: registerForm?.securityAnswer1 || "",
      securityAnswer2: registerForm?.securityAnswer2 || ""
    }
  });
  const { errorMessage } = useMultipleFormErrors(formErrors);

  const executePostRegistration = (form) => {
    Object.assign(form, {
      institutionId: config?.id,
      memberId: member_id?.enabled ? form.memberId.replace(/\D?/g, "") : form.memberId
    });
    const payload = constructRegisterPayload(form);
    dispatch(postRegister(payload))
      .unwrap()
      .then(res => {
        dispatch(setLoans(res?.loans));
        dispatch(setForm(form));
        getDetails(res.token, form?.securityAnswer2);
      })
      .catch(() => { });
  };

  const getDetails = (token, securityAnswer) => {
    const detailsPayload = { institutionId: config?.id, token };
    dispatch(getInstitutionDetails(detailsPayload))
      .unwrap()
      .then(() => {
        navigate("/create-password", {
          state: {
            securityAnswer,
            institutionId: config?.id
          }
        });
      })
      .catch(() => { });
  };

  return (
    <>
      <Title title="Register" subTitle={config?.instructions?.registration?.get_started || ""} />
      <Alert message={errorMessage} severity="error" />
      <Alert message={registerState?.apiError?.response} severity={registerState?.apiError?.severity} />
      <form onSubmit={handleSubmit(executePostRegistration)} noValidate={true}>
        <Grid
          container
          spacing={3}
          component="fieldset"
          sx={{ border: "none", padding: "0" }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography component="p" variant="body2" noWrap>* Required Field </Typography>
            </Box>
          </Grid>

          <UserInfo formErrors={formErrors} register={register} reduxForm={registerForm} dispatch={dispatch}  />

          <AccountDetails formErrors={formErrors} register={register} config={config} />

          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <FormControl component="fieldset" error={!!formErrors?.confirmation}>
                <Controller
                  name="confirmation"
                  control={control}
                  defaultValue={false}
                  rules={schema.checkBox.properties}
                  render={({
                    field: props
                  }) => {
                    return (
                      <FormControlLabel
                        label={
                          <Box>
                            <Typography component="span">
                              {"By clicking this box you are submitting e-sign consent and agreeing to the terms and conditions stated in the "}
                            </Typography>
                            <Link target="_blank" rel="noopener" href={config?.terms_and_conditions_url}>
                              Terms of Service
                            </Link>
                          </Box>
                        }
                        control={
                          <Checkbox {...props} inputProps={{ "aria-required": "true" }} />
                        }
                      />
                    );
                  }}
                />
                <FormHelperText>{formErrors?.confirmation?.message || " "}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={0} md={6} />
            <Grid item xs={12} md={6}>
              <Box sx={{ position: "relative" }}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  type="submit"
                  disabled={registerState.isSubmitting}
                >
                  Submit
                </Button>
                {registerState?.isSubmitting && <StyledCircularProgress />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default Validation;
