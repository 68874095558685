import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetManualLinkState as clearAchDetails, resetManualLinkState } from "../../store/achDetails/achDetails.slice";
import { clearVerificationState } from "../../store/achWeb/achVerification.slice";
import { resetAuth, setTimeoutMessage } from "../../store/auth/auth.slice";
import { clearLoansState, resetLoans } from "../../store/loans/loans.slice";
import { clearNavHistory, setCurrentLocation, setErrorPageLoaded } from "../../store/navhistory/navhistory.slice";
import { clearPaymentState, resetAchVerificationState } from "../../store/payment/makePayment.slice";
import { clearSavedAccounts } from "../../store/savedAccounts/savedAccounts.slice";
import { NO_AUTH_LOCATIONS, SESSION_TIMEOUT, PAGES_WITHOUT_CRUMBS, BACK_TO_LOGIN } from "../../utils/constants/constants";
import { routeMap } from "../../utils/data/routeMap";
import { DayjsUtcLocalStr } from "../../utils/helpers/dayjsHelpers";
import HeartbeatManager from "./HeartbeatManager";
import NavigationAnnouncer from "./NavigationAnnouncer";

const NavigationManager = () => {
  // Named selectors
  const authState = (state) => state.auth;
  const navState = (state) => state.navHistory;
  const institutionState = (state) => state.institution;

  // Use named selectors
  const { timeoutMessage, flow } = useSelector(authState);
  const { currentLocation } = useSelector(navState);
  const { config } = useSelector(institutionState);

  // Hooks
  const [pageForAnnouncer, setPageForAnnouncer] = useState();
  const [clearFlow, setClearFlow] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const currentLocationRef = useRef(currentLocation);

  const isUnprotectedPage = NO_AUTH_LOCATIONS.find(path => location.pathname === path);
  const pagesWithoutCrumbs = PAGES_WITHOUT_CRUMBS.find(path => location.pathname === path);

  useEffect(() => {
    dispatch(setErrorPageLoaded(false));
    setBackToLoginBreadCrumb();
    updateCurrentLocation();
    updateAnnouncer();

    if (isUnprotectedPage) {
      dispatch(resetAuth());
      dispatch(resetLoans());
      dispatch(clearSavedAccounts());
    }

    if (timeoutMessage) {
      dispatch(setTimeoutMessage(SESSION_TIMEOUT));
    }

    clearingSuccessPage();
    clearBreadCrumbsIfNeeded();
  }, [location.pathname]);

  useEffect(() => {
    const paymentFlow = [
      "/make-payment",
      "/link-bank-account",
      "/connect-bank",
      "/pending-payments",
      "/payment-confirmed",
      "/review-payment",
      "/autopay/setup",
      "/autopay/authorize",
      "/autopay/edit"
    ];
    const isInPaymentFlow = paymentFlow.includes(location.pathname);
    if (!isInPaymentFlow) {
      dispatch(clearPaymentState());
      dispatch(clearAchDetails());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!flow && clearFlow) {
      dispatch(clearPaymentState());
      dispatch(clearLoansState());
      dispatch(clearAchDetails());
      dispatch(clearVerificationState());
      setClearFlow(false);
    }
    if (flow) {
      setClearFlow(true);
    }
  }, [dispatch, flow, clearFlow]);

  const setBackToLoginBreadCrumb = () => {
    const isBackToLoginRoute = BACK_TO_LOGIN.find(path => location.pathname === path);

    if (isBackToLoginRoute) {
      const routeItem = routeMap.get("/login");
      const dataForCurrentLocation = {
        title: routeItem.title,
        route: routeItem.route
      };
      dispatch(setCurrentLocation(dataForCurrentLocation));
    }
  };

  const updateCurrentLocation = () => {
    const routeMapData = routeMap.get(location.pathname);
    if (!routeMapData) { return; }

    const dataForCurrentLocation = {
      title: routeMapData.title,
      route: routeMapData.route,
      time: DayjsUtcLocalStr(),
      params: location?.search || null
    };
    dispatch(setCurrentLocation(dataForCurrentLocation));
    currentLocationRef.current = dataForCurrentLocation;
  };

  const updateAnnouncer = () => {
    const title = currentLocationRef.current?.title || "";
    setPageForAnnouncer(title);
  };

  const clearBreadCrumbsIfNeeded = () => {
    if (config?.express_pay_only) {
      PAGES_WITHOUT_CRUMBS.push("/express-pay");
    }

    if (pagesWithoutCrumbs) {
      dispatch(clearNavHistory());
    }
  };

  const clearingSuccessPage = () => {
    if (location.pathname === "/accounts") {
      dispatch(clearNavHistory());
      dispatch(resetManualLinkState());
      dispatch(resetAchVerificationState());
      dispatch(clearPaymentState());
    }
  };

  return (
    <>
      <NavigationAnnouncer currentPageName={pageForAnnouncer} />
      <HeartbeatManager currentLocationRef={currentLocationRef} />
    </>
  );
};

export default NavigationManager;
