import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../components";
import { SDKContext } from "../context/SDKProvider";

export const SDKButton = () => {
  const { isLoading } = useContext(SDKContext);
  return (
    <Button
      color="secondary"
      component={Link}
      disabled={isLoading}
      loading={isLoading}
      to="/connect-bank"
    >
      CONNECT YOUR BANK
    </Button>
  );
};
