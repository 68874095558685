import { useState, useEffect, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { sdkSignRequest, sdkVerifyResponse } from "../api/post";
import { SDKContext } from "../context/SDKProvider";
import EXPERIENCE from "../schemas/experiences.json";

const SDK_SWBC = new window.SWBCSDK();
let SDK_SERVICE;
let SDK_EXPERIENCE;

const defaultInit = {
  "IntegrationID": process.env.REACT_APP_ACH_INTEGRATION_PARTNER,
  "Element": "enhanced-ach-experience",
  "Experience": "VERIFY_ACH_ENHANCED_SINGLE_ACCOUNT",
  "Mode": "selection"
};

const allowedRoutes = [
  "/accounts",
  "/autopay/setup",
  "/autopay/edit",
  "/connect-bank",
  "/make-payment",
  "/profile"
];

export const useSDK = ({ initialization, token, payload }) => {
  const { setIsLoading }  = useContext(SDKContext);
  const [ shouldReload, setShouldReload ] = useState(false);
  const [ signedMessage, setSignedMessage ] = useState("");
  const { pathname } = useLocation();

  const isRouteAllowed = allowedRoutes.includes(pathname);

  const sign = useMutation({
    mutationFn: sdkSignRequest,
    onSettled: (data) => {
      if (data.data.signedMessage) {
        setSignedMessage(data.data.signedMessage);
        setIsLoading(false);
      }
    }
  });

  const verify = useMutation({ mutationFn: sdkVerifyResponse });

  const start = (event = initialization) => {
    setIsLoading(true);
    setShouldReload(true);
    const INIT = { ...defaultInit, ...event };
    SDK_EXPERIENCE = EXPERIENCE[INIT.Experience];
    SDK_SERVICE = new SDK_SWBC[SDK_EXPERIENCE.type](INIT);
    SDK_SERVICE[SDK_EXPERIENCE.handler](responseHandler);
    SDK_SERVICE.setEventHandler(eventHandler);
  };

  const openFlow = (event) => {
    SDK_EXPERIENCE = EXPERIENCE[event.experience];
    sign.mutate({ event, token });
  };

  const eventHandler = (event) => {
    if (event.type === "InitializationAck"){
      openFlow(payload);
    }
  };

  const responseHandler = (event) => {
    verify.mutate({ event, token });
  };

  const close = () => {
    SDK_SERVICE[SDK_EXPERIENCE.closeFlow]();
    sign.reset();
    verify.reset();
  };

  useEffect(() => {
    if (pathname === "/connect-bank" && signedMessage) {
      SDK_SERVICE[SDK_EXPERIENCE.start](signedMessage);
      if (!shouldReload){
        return () => {
          close();
          start();
        };
      }
    }
    return () => setShouldReload(false);
  }, [pathname, signedMessage]);

  return {
    start,
    close,
    isRouteAllowed
  };
};
