import React, { useState, useEffect, useRef } from "react";
import { Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Alert, Box, Divider, Typography } from "../../components/mui";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import Title from "../../components/Title";
import { getLoans, resetAutopayApiResponses } from "../../store/loans/loans.slice";
import { clearIdempotency, clearLoanState, getIdempotencyToken, clearPaymentForm } from "../../store/payment/makePayment.slice";
import { clearGetAccountsErrors, getSavedAccounts } from "../../store/savedAccounts/savedAccounts.slice";
import { REGISTERED, NO_ACCOUNTS_INFO } from "../../utils/constants/constants";
import AccountCard from "./AccountCard";

const Accounts = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const loanState = (state) => state.loans;
  const authState = (state) => state.auth;
  const paymentState = (state) => state.payment;

  // Pass in named selectors and gets state from redux
  const { list: loans, isLoading } = useSelector(loanState);
  const { config, details } = useSelector(institutionState);
  const { authToken, flow } = useSelector(authState);
  const { idempotency } = useSelector(paymentState);

  // Hooks
  const dispatch = useDispatch();
  const location = useLocation();

  // Local State
  const [alertMessage, setAlertMessage] = useState("");
  const [messageSeverity, setMessageSeverity] = useState("");
  const autoPayMessageRef = useRef(location.state?.autoPayMessage);

  useEffect(() => {
    dispatch(resetAutopayApiResponses());
    dispatch(clearGetAccountsErrors());
    dispatch(clearPaymentForm());
    dispatch(clearIdempotency());
    dispatch(clearLoanState());
    dispatch(getIdempotencyToken(authToken.value));
  }, []);

  useEffect(() => {
    if (flow === REGISTERED && authToken.value) {
      dispatch(getSavedAccounts(authToken.value));
    }
  }, [authToken.value, flow, dispatch]);

  useEffect(() => {
    if (!loans || loans.length === 0) {
      dispatch(getLoans(authToken.value))
        .unwrap()
        .then(res => {
          const hasLoans = res?.results && res.results.length > 0;
          if (!hasLoans) {
            setNoLoansFound();
          }
        })
        .catch(() => {
          setNoLoansFound();
        });
    }
  }, []);

  const setNoLoansFound = () => {
    setAlertMessage(NO_ACCOUNTS_INFO);
    setMessageSeverity("info");
  };

  return (
    isLoading || idempotency?.isSubmitting
      ?
      (
        <Box role="group">
          <Box sx={{ position: "relative", height: "5rem" }}>
            <StyledCircularProgress size={"2.5rem"} />
          </Box>
          <Typography variant="h1" sx={{ textAlign: "center" }}>
            <output aria-live="assertive">Loading account loans...</output>
          </Typography>
        </Box>
      )
      :
      (
        <>
          <Title title="Accounts" />
          <Alert message={alertMessage} severity={messageSeverity || "error"} />
          <Alert message={autoPayMessageRef.current} severity="success" />
          {
            loans &&
            <Stack
              divider={<Divider sx={{ width: "100%", margin: "auto" }} aria-hidden="true" />}
              sx={{ margin: "0px", padding: "0px", gap: "1rem", display: "grid" }}
            >
              {
                [...loans].sort((a, b) => a.number - b.number)
                  .map((result, index, array) => (
                    result.inactive
                      ? null
                      : (
                        <React.Fragment key={array[index].id}>
                          <AccountCard
                            config={config}
                            data={result}
                            details={details}
                            id={`Account-${array[index].id}`}
                            idempotencyToken={idempotency.token}
                            flow={flow}
                          />
                        </React.Fragment>
                      )
                  ))
              }
            </Stack>
          }
        </>
      )
  );
};

export default Accounts;
