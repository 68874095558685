import React, { useState, createContext } from "react";

export const SDKContext = createContext({
  isLoading: false,
  setIsLoading: () => {}
});

export const SDKContextProvider = ({ children }) => {
  const [ isLoading, setIsLoading ] = useState(false);

  return (
    <SDKContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </SDKContext.Provider>
  );
};
