import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dayjs from "dayjs";
import { Box, Divider, IconButton, Typography } from "../../../../components";
import { capitalizeFirstLetter } from "../../../../utils/helpers/commonHelpers";

const cardStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  margin: "1rem 0"
};

const PaymentHistoryCard = (props) => {
  const { data, setPaymentToDisplay, focusOnSummary, setErrorMessage, openModalIfMobile } = props;
  const loanType = capitalizeFirstLetter(data?.transaction_type);
  return (
    <>
      <Box sx={cardStyle}>
        <Box sx={{ flex: 1 }}>
          <Typography variation="body1" component="p">
            {dayjs(data.transaction_date).format("MMM DD")}
          </Typography>
        </Box>
        <Box sx={{ flex: 3, display: "flex", flexDirection: "column" }}>
          <Box>
            <Typography variation="body1" component="p">
              {loanType}
            </Typography>
          </Box>
          <Box>
            <Typography variation="body1" component="p">
              {isNaN(data.tracking) ? "Card Payment" : "ACH Payment"}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variation="body1" component="p">
            {(data.amount + data.fee).toLocaleString("en-US", { style: "currency", currency: "USD" })}
          </Typography>
        </Box>
        <Box sx={{ flex: 2 }}>
          <IconButton
            aria-label={`expand transaction details for payment made on ${data.transaction_date}`}
            onClick={() => {
              setErrorMessage("");
              setPaymentToDisplay(data);
              openModalIfMobile();
              focusOnSummary();
            }}
          >
            <ArrowForwardIcon fontSize="small" color="primary"/>
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default PaymentHistoryCard;
