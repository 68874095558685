import React from "react";
import { CardMedia as Card } from "@mui/material";
import swivelLogo from "../../../../assets/img/Swivel_logo_RGB.png";
/**
 * Component for showing logo image as a MaterialUI Card Media.
 *
 * @component
 * @param {string} name Name of company
 * @param {string} url Filepath/URL of logo image
 * @param {number} setWidth Width override (in pixels) for max image width
 * @param {number} setHeight Height override (in pixels) for max image height
 * @param {boolean} centerLogo Check if image should be centered
 * @example
 * const name = 'MyBank'
 * const url = '../assets/MyBankLogo.png'
 * const centerLogo
 * return (
 *   <CardMedia  />
 * )
 */

export const CardMedia = ({ name, url, setWidth, setHeight, centerLogo }) => {
  const validatedUrl = url || swivelLogo;

  const maxWidth = setWidth > 0 ? setWidth : 200;
  const maxHeight = setHeight > 0 ? setHeight : 60;
  const logoName = `${name || "Swivel"} Logo`;

  const logoStyle = {
    display: "flex",
    maxWidth,
    maxHeight,
    margin: centerLogo && "auto"
  };

  const errorStyle = {
    ...logoStyle,
    margin: "auto",
    paddingBlock: "1rem"
  };

  return (
    <Card
      alt={logoName}
      aria-label={logoName}
      component="img"
      data-cy="cardMedia"
      src={validatedUrl}
      sx={url ? logoStyle : errorStyle}
    />
  );
};
