import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Typography } from "../../components/mui";
import { setErrorPageLoaded } from "../../store/navhistory/navhistory.slice";
import * as Constants from "../../utils/constants/constants";

const ErrorPage = ({ title, subTitle }) => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const maintenanceState = (state) => state.maintenance;

  // Pass in named selectors and gets state from redux
  const { config } = useSelector(institutionState);
  const { maintenanceError } = useSelector(maintenanceState);

  // Hooks
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setErrorPageLoaded(true));
  }, [dispatch]);

  const titleValue = title || Constants.ERR_404_TITLE;
  const message = subTitle || Constants.ERR_404_SUBTITLE;
  const isConnectionError = !config || maintenanceError;
  const errorMessage = isConnectionError ? Constants.NO_CONNECTION_ERR : "";

  return (
    <>
      {
        !errorMessage
          ?
          <div>
            {titleValue && <Typography variant="h1" component="h1" flexGrow={1} id="title" sx={{ textAlign: "center" }}>{titleValue}</Typography>}
            {message && <Typography variant="body1" component="p" flexGrow={1} id="titleMessage" sx={{ textAlign: "center" }}>{message}</Typography>}
          </div>
          : null
      }
      <Alert message={errorMessage} severity="error" />
    </>
  );
};

export default ErrorPage;
