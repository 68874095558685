import React from "react";
import { Divider, Stack } from "@mui/material";
import dayjs from "dayjs";
import { Summary } from "../../../../components/custom/features/Summary";
import { Grid, Typography } from "../../../../components/mui";
import { getAccountLastFour } from "../../../../utils/helpers/handleAccounts";


const PaymentSummary = ({ savedAccount, paymentDetails, loan, flow, convFee, isCardPayment, cardBrand }) => {
  const checkFlowForAccount = flow === "registered" ? savedAccount?.last_4 : getAccountLastFour(paymentDetails?.accountNumber);
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12} md={6} container>
        <Summary
          rows={[
            {
              title: "Pay To Account Ending in:",
              value: getAccountLastFour(loan?.posting_account_number)
            },
            {
              title: "Payment Date:",
              value: isCardPayment ? dayjs().format("MM/DD/YYYY") : paymentDetails?.date
            },
            {
              title: "Pay Using:",
              value: `${isCardPayment ? "Card" : "Bank"}
                    ending in ${isCardPayment ? getAccountLastFour(paymentDetails.cardNumber) : checkFlowForAccount}`
            }
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6} container>
        <Summary
          rows={[
            {
              title: isCardPayment ? "Card Brand:" : "Routing Number:",
              value: isCardPayment ? cardBrand : savedAccount?.banking_details?.routing_number ?? paymentDetails?.routingNumber
            },
            {
              title: "Bill Amount:",
              value: `$${paymentDetails?.amount}`
            },
            {
              title: "Fees:",
              value: `$${Number(convFee).toFixed(2)}`
            }
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} container direction="row-reverse">
        <Grid item xs={6}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">
              Total:
            </Typography>
            <Typography variant="body1">
              ${(Number(convFee) + Number(paymentDetails?.amount)).toFixed(2).toLocaleString()}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

    </Grid>
  );
};

export default PaymentSummary;
