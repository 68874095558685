import React from "react";
import { Button as MUIButton } from "@mui/material";
import styled from "styled-components";
import { StyledCircularProgress } from "../../../styled/StyledCircularProgress";

// Styles for Button
const Container = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  width: 100%;
`;

/**
 * Button component with loading spinner
 *
 * @component
 * @return {JSX.Element}
 */

export const Button = (props) => {
  const buttonProps = { ...props };
  delete buttonProps.loading;

  return (
    <Container>
      <MUIButton
        disabled={props.disabled || false}
        fullWidth
        size="large"
        variant="contained"
        {...buttonProps}
      >
        {props.children}
      </MUIButton>
      {props.loading ? <StyledCircularProgress /> : null}
    </Container>
  );
};
