import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Accounts from "../pages/Accounts/Accounts";
import AuthorizeAutopay from "../pages/AuthorizeAutoPay";
import { SetupAutoPay } from "../pages/AutopaySetup";
import ConnectBank from "../pages/ConnectBank";
import { DeeplinkLogin } from "../pages/DeeplinkLogin";
import { EditAutopaySchedule } from "../pages/EditAutopaySchedule";
import ErrorPage from "../pages/Error/ErrorPage";
import ExpressPay from "../pages/ExpressPay/ExpressPay";
import LinkBankAccount from "../pages/LinkBankAccount/LinkBankAccount";
import Login from "../pages/Login/Login";
import CreatePassword from "../pages/Password/CreatePassword";
import MakePayment from "../pages/Payment/MakePayment";
import PaymentConfirmedPage from "../pages/Payment/PaymentConfirmedPage";
import PaymentHistory from "../pages/PaymentHistory/PaymentHistory";
import PendingPayments from "../pages/PendingPayment/PendingPaymentPage";
import { Profile } from "../pages/Profile/Profile";
import { ProfileChangeEmail } from "../pages/ProfileChangeEmail/ProfileChangeEmail";
import { ProfileChangePassword } from "../pages/ProfileChangePassword/ProfileChangePassword";
import Register from "../pages/Register";
import RegisterGuest from "../pages/RegisterGuest";
import CheckEmail from "../pages/ResetPassword/CheckEmail/index";
import Request from "../pages/ResetPassword/Request";
import ResetPassword from "../pages/ResetPassword/Reset";
import ReviewPayment from "../pages/ReviewPayment";
import { StateOfResidence } from "../pages/StateOfResidence";
import SuccessPage from "../pages/Success/index";
import { REGISTER_NEW_USER } from "../utils/constants/constants";
import { ProtectedRoute } from "./ProtectedRoute";

const RouterConfig = ({ authToken, config, loan, redirectionPath, shouldClearBreadcrumbs }) => {

  const paymentState = (state) => state.payment;

  const { manuallyLinkFlow, loanType } = useSelector(paymentState);

  return (
    <Routes>
      {/* Public Routes */}
      {
        config?.express_pay_only
          ?
          <>
            <Route index path="/express-pay" element={<ExpressPay />} />
            <Route path="/" element={<ExpressPay />} />
          </>
          :
          <>
            <Route index path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/check-email" element={<CheckEmail />} />
            <Route path="/reset-password" element={<Request />} />
            <Route path="/success/password-reset" element={
              <SuccessPage
                title="Success!"
                subTitle="Your password has been reset. Please login with your new password."
              />
            }
            />
            {config?.express_pay_enabled ? <Route path="/express-pay" element={<ExpressPay />} /> : null}
          </>
      }
      <Route path="/deeplink-login" element={<DeeplinkLogin />} />
      <Route path="/non-validation/create-password" element={<CreatePassword />} />

      {/* Protected Routes: Must have auth token  */}
      <Route element={<ProtectedRoute isAllowed={authToken} redirectPath={redirectionPath} />}>
        <Route path="/gate/reset-password" element={<ResetPassword />} />
        <Route path="/accounts" element={<Accounts />} />
        <Route path="/history" element={<PaymentHistory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/password" element={<ProfileChangePassword />} />
        <Route path="/profile/email" element={<ProfileChangeEmail />} />
        <Route path="/profile/state-of-residence" element={<StateOfResidence />} />
        <Route path="/register/guest" element={<RegisterGuest />} />

        <Route element={<ProtectedRoute isAllowed={(authToken && loanType)} redirectPath="/accounts" />}>
          <Route path="/payment-confirmed" element={<PaymentConfirmedPage />} />
        </Route>

        {/* TODO: Need to add logic for isAllowed */}
        <Route element={<ProtectedRoute
          isAllowed={(authToken && loan) || (authToken && !loan)} redirectPath="/accounts" />
        }>
          <Route path="/connect-bank" element={ <ConnectBank />} />
        </Route>

        <Route element={<ProtectedRoute
          isAllowed={(authToken && loan) || (authToken && !loan && manuallyLinkFlow?.route === "/profile")}
          redirectPath="/accounts"
        />}>
          <Route path="/link-bank-account" element={<LinkBankAccount />} />
        </Route>

        {/* Protected Routes: Must have an auth token and a loan set in redux for payment flow */}
        <Route element={<ProtectedRoute isAllowed={authToken && loan} redirectPath="/accounts" />}>
          <Route path="/autopay/setup" element={<SetupAutoPay />} />
          <Route path="/make-payment" element={<MakePayment />} />
          <Route path="/review-payment" element={<ReviewPayment />} />
          <Route path="/pending-payments" element={<PendingPayments />} />
          <Route path="/autopay/authorize" element={<AuthorizeAutopay />} />
        </Route>

        {/* Protected Routes: Must have an auth token and a loan with and autopay set in redux for autopay flow */}
        <Route element={<ProtectedRoute isAllowed={authToken && loan?.autopay} redirectPath="/accounts" />}>
          <Route path="/autopay/edit" element={<EditAutopaySchedule />} />
        </Route>

        {/* Protected Routes: Must have shouldClearBreadcrumbs */}
        <Route element={<ProtectedRoute isAllowed={shouldClearBreadcrumbs} redirectPath={redirectionPath} />}>
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/success/registration" element={
            <SuccessPage
              title="Successful Registration!"
              subTitle="Please click Continue to login to Accounts and Loans."
              flow={REGISTER_NEW_USER}
            />
          } />
        </Route>
      </Route>

      {/* 404 Redirect */}
      <Route path="*" element={<ErrorPage />} />
    </Routes >
  );
};

export default RouterConfig;
