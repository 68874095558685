import React, { useCallback, useEffect } from "react";
import { Grid } from "@mui/material";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Typography, Button } from "../../../components";
import Title from "../../../components/Title";
import { putStateOfResidence, clearStateOfResidenceResponse } from "../../../store/auth/auth.slice";
import { setPaymentDetails, setConvFee, getConvenienceFeeMicroservice } from "../../../store/payment/makePayment.slice";
import { EXPRESS_PAY } from "../../../utils/constants/constants";
import feeSources from "../../../utils/data/feeSources";
import { setAmountOnLoad } from "../../../utils/helpers/cardHelpers";
import { getConvenienceFee, getPaymentConfig } from "../../../utils/helpers/getPaymentConfig";
import TabContainer from "./tabs/TabContainer";

const MakePayment = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const paymentState = (state) => state.payment;
  const authState = (state) => state.auth;

  // Pass in named selectors and gets state from redux
  const { config, details } = useSelector(institutionState);
  const { user, authToken, stateOfResidence: userStateOfResidence, flow } = useSelector(authState);
  const {
    loan,
    form,
    paymentType,
    formErrorMessage,
    loanType,
    warningMessage,
    isAchVerificationComplete,
    stateOfResidence,
    fee
  } = useSelector(paymentState);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isPaymentsEnabled = details?.ach_payment_enabled || details?.card_payment_enabled;
  const { shouldDisableAmount, card, ach } = getPaymentConfig(details);
  const checkStateOfResidence = userStateOfResidence.hasStateOfResidence
    ? !stateOfResidence.isConfirmed
    : false;

  const formTraits = {
    convFee: form?.convFee,
    shouldDisableAmount,
    ach: {
      maxAchAmount: ach.maxAchAmount,
      minAchAmount: ach.minAchAmount
    },
    card: {
      maxCardAmount: card.maxCardAmount,
      minCardAmount: card.minCardAmount,
      acceptedCardTypes: card.acceptedCardTypes
    }
  };

  const submitButtonDisable = config.services?.payments?.convenience_fee?.enabled
    ? checkStateOfResidence || userStateOfResidence?.api?.isSubmitting || fee.isLoading
    : false;

  const submitButtonLoading = config.services?.payments?.convenience_fee?.enabled
    ? userStateOfResidence?.api?.isSubmitting || fee.isLoading
    : false;

  useEffect(() => {
    dispatch(clearStateOfResidenceResponse());
  }, [dispatch]);

  useEffect(() => {
    setAmountOnLoad(loan, form, form?.convFee, dispatch);
  }, [dispatch, form.convFee, form, loan]);

  const getConvenienceFeeCallback = useCallback(() => {
    let fee;
    try {
      fee = getConvenienceFee(details, { loan: loanType, payment: paymentType });
    } catch (error) {
      fee = 0;
    }
    dispatch(setConvFee(fee));
  }, [details, loanType, paymentType, dispatch]);

  const submitFormCallback = (form) => {
    // utility function inside this function to prevent having to passing too many variables
    let feeSource = feeSources.json;
    const setAndUpdateDetails = () => {
      const paymentDetails = { ...form, feeSource };
      if (flow === EXPRESS_PAY || !config?.services?.payments?.convenience_fee?.enabled) {
        form.date = dayjs().format("MM/DD/YYYY");
        paymentDetails.date = form.date;
        dispatch(setPaymentDetails(paymentDetails));
        navigate("/review-payment");
      } else {
        const stateOfResidencePayload = {
          token: authToken?.value,
          user_id: user?.id,
          state_of_residence: form.state_of_residence,
          brandDetails: {
            brand_phone: config?.brand_phone,
            brand_name: config?.brand_name
          }
        };
        dispatch(putStateOfResidence(stateOfResidencePayload))
          .unwrap()
          .then((response) => {
            form.state_of_residence = response.state.state_of_residence;
            dispatch(setPaymentDetails(paymentDetails));
            navigate("/review-payment");
          });
      }
    };

    const isAchPayment = paymentType.toLowerCase() === "ach";

    form.date = isAchPayment ? dayjs(form.date).format("MM/DD/YYYY") : null;
    form.state_of_residence = isAchPayment ? form?.stateOfResidenceAchDropdown : form?.stateOfResidenceCardDropdown;

    if (!details.portal_account_id) {
      getConvenienceFeeCallback();
      setAndUpdateDetails();
      feeSource = feeSources.json;
      return;
    }

    const convenienceFeePayload = {
      transaction_amount: form.amount,
      apply_to_type: [loan.type],
      state_of_residence: form.state_of_residence,
      pay_from_type: isAchPayment ? "ach" : "card",
      portal_account_id: details.portal_account_id,
      authToken: authToken.value
    };

    dispatch(getConvenienceFeeMicroservice(convenienceFeePayload))
      .unwrap()
      .then(res => {
        dispatch(setConvFee(res.fee));
        feeSource = res.isDefault ? feeSources.microserviceDefault : feeSources.microserviceSchedule;
      })
      .catch(() => {
        getConvenienceFeeCallback();
        feeSource = feeSources.json;
      })
      .finally(() => {
        setAndUpdateDetails();
      });
  };

  return (
    <>
      <Title title="Make A Payment" />
      <Alert message={formErrorMessage} severity="error" />
      <Alert message={warningMessage} severity="warning" />
      <Alert message={isAchVerificationComplete ? "Account(s) Successfully Linked" : null} severity="success" />
      <Alert message={userStateOfResidence?.api?.response?.response} severity={userStateOfResidence?.api?.response?.severity} />

      {isPaymentsEnabled &&
        <Typography component="p" required variant="body2" sx={{ marginBlock: "0.5rem" }}>* Required Field</Typography>
      }
      <Grid container spacing={3}>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} md={6}>
          <TabContainer
            config={config}
            formTraits={formTraits}
            loan={loan}
            submitForm={submitFormCallback}
            details={details}
            paymentType={paymentType}
          />
        </Grid>
        <Grid item xs={0} md={3} />
        <Grid item xs={12} container spacing={1} direction="row-reverse">
          <Grid item xs={0} md={3} />
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              type="submit"
              form="payment-form"
              fullWidth
              color="primary"
              size="large"
              disabled={submitButtonDisable}
              loading={submitButtonLoading}
            >
              Review Payment
            </Button>
          </Grid>
          <Grid item xs={0} md={3} />
        </Grid>
      </Grid>
    </>
  );
};

export default MakePayment;
