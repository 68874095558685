import React, { useState, useLayoutEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { TextField, InputAdornment, FormControl, FormControlLabel, Checkbox, FormHelperText } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { Typography, Box, Link, Grid, IconButton, Alert, BulletList } from "../../components/mui";
import { postCreatePassword } from "../../store/auth/auth.slice";
import { resetLoans } from "../../store/loans/loans.slice";
import { setLoan } from "../../store/payment/makePayment.slice";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../utils/validators/formHookSchema";
import { validate } from "../../utils/validators/validators";

const RegisterGuest = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // Pass in named selectors and gets state from redux
  const { config } = useSelector(institutionState);
  const { user, promote, authToken } = useSelector(authState);

  // Local State
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({ mode: "onBlur" });

  const { errorMessage } = useMultipleFormErrors(errors); // Hook that for handling multiple form errors

  useLayoutEffect(() => {
    dispatch(resetLoans());
    dispatch(setLoan(null));
  }, []);

  const completeRegistration = (form) => {
    const payload = {
      password: form?.password,
      security_answer_2: form?.securityAnswer2,
      token: authToken.value,
      user_id: user?.id
    };

    const navigateRoute = "/success/registration";
    dispatch(postCreatePassword(payload))
      .unwrap()
      .then(() => {
        navigate(navigateRoute);
      })
      .catch(() => { });
  };

  const validateMatchingPasswords = () => {
    const password = getValues("password");
    const confirmPassword = getValues("confirmPassword");
    if (!password || !confirmPassword) return null;
    if (password !== confirmPassword) return "Password must match";
    clearErrors("password");
    clearErrors("confirmPassword");
    return null;
  };

  const passwordValidation = {
    ...schema.password.options,
    validate: (value) => validateMatchingPasswords(value, "password"),
    pattern: validate.newPassword
  };

  const confirmPasswordValidation = {
    ...schema.password.options,
    validate: (value) => validateMatchingPasswords(value, "confirmPassword"),
    pattern: validate.newPassword
  };

  const defaultList = [
    "Minimum length of 8 characters",
    "Lowercase letter (a-z)",
    "Uppercase letter (A-Z)",
    "Special Character",
    "At least one number"
  ];

  return (
    <>
      <Alert message={errorMessage} severity="error" />
      <Alert message={promote?.response?.response} severity={promote?.response?.severity} />
      <form onSubmit={handleSubmit(completeRegistration)} noValidate={true} >
        <Box sx={{ marginBottom: "1.5rem" }}>
          <Typography variant="h1" component="h1" flexGrow={1}>
            Create Account
          </Typography>
          <Typography
            variant="body1"
            component="p"
            flexGrow={1}
            id="subTitle"
          >
            Get more! It just takes a minute. Complete the registration below to take advantage of
            saving payment accounts for future use, viewing your payment history,
            and creating recurring payments (if enabled).
          </Typography>
        </Box>
        <section>
          <BulletList title="Password must have:" items={defaultList} sx={{ marginBottom: "1.5rem" }} />
        </section>
        <Grid component="fieldset" container xs={12} sx={{ marginBottom: "1rem", border: "none", margin: 0, padding: 0 }}>
          <Typography component="legend" variant="h2" sx={{ marginBlock: "0.5rem", marginBottom: "0.7rem" }}>
            Create Password
          </Typography>
          <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
            <TextField
              {...schema.defaultProperties("password")}
              {...register("password", passwordValidation)}
              error={!!errors?.password}
              helperText={errors?.password?.message || " "}
              id="password"
              inputMode="text"
              label="New Password"
              autoComplete="new-password"
              type={isPasswordVisible ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...schema.defaultProperties("confirmPassword")}
              {...register("confirmPassword", confirmPasswordValidation)}
              error={!!errors?.confirmPassword}
              helperText={errors?.confirmPassword?.message || " "}
              id="confirmPassword"
              inputMode="text"
              label="Re-enter Password"
              autoComplete="off"
              type={isConfirmPasswordVisible ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {isConfirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>

        </Grid>

        <Grid component="fieldset" item xs={12} sx={{ marginBottom: "1rem", border: "none", margin: 0, padding: 0 }}>
          <Typography component="legend" variant="h2" sx={{ marginBlock: "0.5rem", marginBottom: "0.7rem" }}>
            Security Question
          </Typography>
          <TextField {...schema.securityQuestionTwo.properties(errors, register, config)} />
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
          <FormControl component="fieldset" error={!!errors?.confirmation}>
            <Controller
              name="confirmation"
              control={control}
              defaultValue={false}
              rules={schema.checkBox.properties}
              render={({
                field: props
              }) => {
                return (
                  <FormControlLabel
                    label={
                      <Box>
                        <Typography component="span">
                          {"By clicking this box you are submitting e-sign consent and agreeing to the terms and conditions stated in the "}
                        </Typography>
                        <Link target="_blank" rel="noopener" href={config?.terms_and_conditions_url}>
                          Terms of Service
                        </Link>
                      </Box>
                    }
                    control={
                      <Checkbox {...props} inputProps={{ "aria-required": "true" }} />
                    }
                  />
                );
              }}
            />
            <FormHelperText>{errors?.confirmation?.message || " "}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item container>
          <Grid item xs={0} md={6} />
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              disabled={promote.isSubmitting}
              loading={promote.isSubmitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
export default RegisterGuest;
