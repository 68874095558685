import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import DomainRedirectDialog from "../../components/modals/DomainRedirectDialog";
import { Typography, Box, Link, Grid, Button } from "../../components/mui";
import { StyledDivider } from "../../components/styled/StyledDivider";
import Title from "../../components/Title";
import { addBreadCrumb } from "../../store/navhistory/navhistory.slice";
import { setIsPaymentSuccessful, clearLoanState } from "../../store/payment/makePayment.slice";
import { EXPRESS_PAY } from "../../utils/constants/constants";
import { routeMap } from "../../utils/data/routeMap";
import { capitalizeFirstLetter } from "../../utils/helpers/commonHelpers";
import { currentDate, dailyCutoffTimeFormat } from "../../utils/helpers/dayjsHelpers";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";
import { validate } from "../../utils/validators/validators";
import { PaymentConfirmedWrapper } from "./PaymentConfirmedPage.styles";

const PaymentConfirmedPage = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;
  const paymentState = (state) => state.payment;
  const navHistoryState = (state) => state.navHistory;

  // Pass in named selectors and gets state from redux
  const { details, config } = useSelector(institutionState);
  const { user, flow, deeplink } = useSelector(authState);
  const { PaymentPostedResponse, form, paymentType, paymentDetails, loanType } = useSelector(paymentState);
  const { currentLocation } = useSelector(navHistoryState);

  // Local State
  const [validSupportLink, setValidSupportLink] = useState(null);
  const [displaySupportLink, setDisplaySupportLink] = useState(false);

  // Hooks
  const materialTheme = useTheme();
  const dispatch = useDispatch();

  let account;
  let type;
  const isCardPayment = paymentType.toLowerCase() === "card";
  if (!isCardPayment) {
    [account, type] = paymentDetails.accountType.split("-");
  }
  const manuallyLinkedAccountType = (account && type) && `${capitalizeFirstLetter(account)} ${capitalizeFirstLetter(type)}`;
  const subTitle = `Thank you, your payment has been successfully submitted for processing!
  A payment confirmation email has been sent to ${user?.email_address}.
  Please retain the email as receipt of your payment. Transactions submitted
  after ${dailyCutoffTimeFormat(details?.daily_cutoff_time)} Central Time will be processed on the next business day.`;

  useEffect(() => {
    dispatch(clearLoanState());
  }, []);

  useEffect(() => {
    dispatch(setIsPaymentSuccessful(true));
    if (details?.brand_support_url) {
      const validLink = validate.url.getValidUrl(details?.brand_support_url);
      setValidSupportLink(validLink);
      setDisplaySupportLink(true);
    }
  }, [details?.brand_support_url, dispatch]);

  // the only allowed breadCrumb for now is to go back to accounts page from payment confirmed as part
  // of the express pay flow. This will change for registered path.
  useEffect(() => {
    if (currentLocation.route.toLowerCase() === "/payment-confirmed") {
      const routeItem = routeMap.get("/accounts");
      const navObj = {
        title: routeItem.title,
        route: routeItem.route,
        time: Date.now()
      };
      dispatch(addBreadCrumb(navObj));
    }
  }, [currentLocation]);

  const boxStyle = {
    display: "flex",
    justifyContent: "space-between"
  };

  const loanAccountNumber = getAccountLastFour(PaymentPostedResponse?.payload?.payToAccounts[0]?.ApplyToAccountNumber);

  return (
    <>
      <Title title="Payment Confirmed" subTitle={subTitle} />
      <PaymentConfirmedWrapper materialTheme={materialTheme}>
        <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }} gridArea="left-col">
          <section>
            <Typography variant="h4" component="h2">Summary</Typography>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Payment date:</Typography>
              <Typography variant="body1" component="p">{currentDate}</Typography>
            </Box>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Payment amount:</Typography>
              <Typography variant="body1" component="p">{`$${form?.total.toFixed(2)}`}</Typography>
            </Box>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Payment confirmation:</Typography>
              <Typography variant="body1" component="p" sx={{ fontWeight: "bold" }}>{PaymentPostedResponse?.payload.tracking}</Typography>
            </Box>
          </section>

          <section>
            <Typography variant="h4" component="h2">Paid To</Typography>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Account ending in:</Typography>
              <Typography variant="body1" component="p">{loanAccountNumber}</Typography>
            </Box>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Type:</Typography>
              <Typography variant="body1" component="p">{loanType}</Typography>
            </Box>
          </section>

          <section>
            <Typography variant="h4" component="h2">Paid By</Typography>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">
                {isCardPayment ? "Card ending in: " : "Account ending in: "}
              </Typography>
              <Typography variant="body1" component="p">
                {
                  (flow === EXPRESS_PAY) && (PaymentPostedResponse?.payload.type === "banking")
                    ?
                    PaymentPostedResponse?.meta?.arg?.last_4
                    :
                    PaymentPostedResponse?.payload.last_4
                }
              </Typography>
            </Box>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Type:</Typography>
              <Typography variant="body1" component="p">
                {isCardPayment
                  ? capitalizeFirstLetter(form?.cardBrand)
                  : manuallyLinkedAccountType || capitalizeFirstLetter(PaymentPostedResponse?.payload.type) || "N/A"
                }
              </Typography>
            </Box>
          </section>
        </Box>

        {
          !useMediaQuery(materialTheme.breakpoints.up("md")) && <StyledDivider />
        }

        <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }} gridArea="right-col">
          <section>
            <Typography variant="h4" component="h2">{details?.brand_name}</Typography>
            <Typography variant="body1" component="p">{details?.brand_address}</Typography>
            <Typography variant="body1" component="p">{`${details?.brand_city}, ${details?.brand_state}`}</Typography>
            <Typography variant="body1" component="p">{details?.brand_zip}</Typography>
          </section>

          <section>
            <Typography variant="h4" component="h2">Contact</Typography>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Telephone:</Typography>
              <Link href={`tel:${details?.brand_phone}`}>{details?.brand_phone}</Link>
            </Box>
            <Box sx={boxStyle}>
              <Typography variant="body1" component="p">Email:</Typography>
              <Link href={`mailto:${details?.brand_email}`}>{details?.brand_email}</Link>
            </Box>
            {
              displaySupportLink &&
              <Box sx={boxStyle}>
                <Typography variant="body1" component="p">Website:</Typography>
                <DomainRedirectDialog
                  brand={details?.brand_name}
                  url={validSupportLink}
                  message={
                    `Please note: following this link opens a new browser tab to ${details?.brand_support_url},
                      ${details?.brand_name}'s website. If you wish to leave, select Proceed. If not, select No Thanks.`} />
              </Box>
            }
          </section>
        </Box>
      </PaymentConfirmedWrapper>

      {
        (flow === EXPRESS_PAY) && (config?.express_pay_only === false) && (!deeplink?.api?.response)
          ?
          <>
            <StyledDivider />
            <Grid item container>
              <Grid item xs={0} md={6} />
              <Grid item xs={12} md={6}>
                <Box sx={{ position: "relative" }}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    component={RouterLink}
                    role="button"
                    to={"/register/guest"}
                  >
                    Register
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
          :
          null
      }
    </>
  );
};

export default PaymentConfirmedPage;
