import React from "react";
import Title from "../../components/Title";

const ConnectBank = () => {
  return (
    <>
      <Title title="Connect your bank" />
    </>
  );
};

export default ConnectBank;
