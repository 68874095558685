import React, { useState, useEffect } from "react";
import { TextField, Button, useTheme } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Typography } from "../../components/mui";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import { Form } from "../../components/styled/StyledForm";
import Title from "../../components/Title";
import {
  postExpressPayAuth,
  setFirstName,
  setFirstSecurityQuestion,
  setLastName,
  setMemberAccount,
  setAuthToken,
  resetForm
} from "../../store/auth/auth.slice";
import { getInstitutionDetails } from "../../store/institution/institution.slice";
import { setLoans } from "../../store/loans/loans.slice";
import { useMultipleFormErrors } from "../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../utils/validators/formHookSchema";
import { validate } from "../../utils/validators/validators";

const ExpressPay = () => {
  // Named selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // Pass in named selectors and gets state from redux
  const { config, isInstitutionDetailsLoading } = useSelector(institutionState);
  const { form, guestAuth: { isSubmitting, apiError } } = useSelector(authState);

  // Local State
  const [disableButton, setDisableButton] = useState(false);

  const memberIdEnabled = config?.services?.express_pay?.fields?.member_id?.enabled;

  // Hooks
  const options = {
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm(options);
  const { errorMessage } = useMultipleFormErrors(errors);
  const materialTheme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setDisableButton(isSubmitting || isInstitutionDetailsLoading);
  }, [isSubmitting, isInstitutionDetailsLoading]);

  // Handlers
  const handleFirstName = (event) => {
    dispatch(setFirstName(event.target.value));
  };

  const handleLastName = (event) => {
    dispatch(setLastName(event.target.value));
  };

  const handleMemberAccount = (event) => {
    dispatch(setMemberAccount(event.target.value));
  };

  const handleFirstSecurityQuestion = (event) => {
    dispatch(setFirstSecurityQuestion(event.target.value));
  };

  const handleGuestAuth = (event) => {
    event.institutionId = config?.id;
    event.memberAccount = memberIdEnabled ? event.memberAccount.replace(/\D?/g, "") : event.memberAccount;
    dispatch(postExpressPayAuth(event))
      .unwrap()
      .then((response) => {
        response.institutionId = config?.id;
        dispatch(setAuthToken(response.token));
        dispatch(setLoans(response.loans));
        dispatch(getInstitutionDetails(response))
          .unwrap()
          .then(() => {
            navigate("/accounts");
            dispatch(resetForm());
          })
          .catch(() => { });
      })
      .catch(() => { });
  };

  const sectionStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    border: "none",
    padding: "0px",
    margin: "0px"
  };

  const sectionTitleStyle = {
    marginBottom: "0.875rem"
  };

  return (
    <>
      <Title title="Express Pay" subTitle={config?.getting_started_text} />
      <Alert message={errorMessage} severity={"error"} />
      <Alert message={apiError?.response} severity={apiError?.severity} />

      <Typography component="p" variant="body2" sx={{ marginBlock: "0.5rem" }}>
        * Required Field
      </Typography>
      <Form
        materialTheme={materialTheme}
        onSubmit={handleSubmit(handleGuestAuth)}
        noValidate={true}
      >
        <Box component="fieldset" sx={sectionStyle} gridArea="left-col">
          <Typography variant="h2" component="legend" sx={sectionTitleStyle}>
            User Information
          </Typography>
          <TextField
            autoComplete="given-name"
            error={!!errors?.firstName}
            fullWidth
            helperText={errors?.firstName ? errors.firstName.message : " "}
            id="first-name"
            inputProps={{ "aria-required": "true" }}
            label="First name"
            required
            size="medium"
            value={form.firstName}
            variant="outlined"
            {...register("firstName", {
              maxLength: {
                value: 40,
                message: "First name must be less than 40 characters"
              },
              onChange: handleFirstName,
              required: "First name is required",
              pattern: validate.name
            })}
          />
          <TextField
            autoComplete="family-name"
            error={!!errors?.lastName}
            fullWidth
            helperText={errors?.lastName ? errors.lastName.message : " "}
            id="last-name"
            inputProps={{ "aria-required": "true" }}
            label="Last name"
            required
            value={form.lastName}
            variant="outlined"
            {...register("lastName", {
              maxLength: {
                value: 40,
                message: "Last name must be less than 40 characters"
              },
              onChange: handleLastName,
              required: "Last name is required",
              pattern: validate.name
            })}
          />
          <TextField {...schema.email.properties(errors, register, form?.emailAddress, dispatch)} />
        </Box>

        <Box component="fieldset" sx={sectionStyle} gridArea="right-col">
          <Typography variant="h2" component="legend" sx={sectionTitleStyle}>
            Account Holder Details
          </Typography>

          <TextField
            autoComplete="off"
            error={!!errors?.memberAccount}
            fullWidth
            helperText={
              errors?.memberAccount ? errors.memberAccount.message : " "
            }
            id="member-account"
            inputProps={{ "aria-required": "true" }}
            label={config ? config?.member_id_terminology : "Member Number"}
            required
            value={form.memberAccount}
            variant="outlined"
            {...register("memberAccount", {
              onChange: handleMemberAccount,
              required: config
                ? `${config?.member_id_terminology} is required`
                : "Member Number is required",
              pattern: {
                value: new RegExp(config?.services?.express_pay?.fields?.member_id?.regex || ""),
                message: config?.services?.express_pay?.fields?.member_id?.error_text || ""
              }
            })}
          />
          {config?.security_question_1 && (
            <TextField
              autoComplete="off"
              error={!!errors?.firstSecurityQuestion}
              fullWidth
              helperText={
                errors?.firstSecurityQuestion
                  ? errors.firstSecurityQuestion.message
                  : " "
              }
              id="first-security-question"
              inputProps={{ "aria-required": "true" }}
              label={config?.security_question_1}
              required
              value={form.firstSecurityQuestion}
              variant="outlined"
              {...register("firstSecurityQuestion", {
                onChange: handleFirstSecurityQuestion,
                required: `${config?.security_question_1} is required`,
                pattern: {
                  value: new RegExp(
                    config?.security_question_1_regex || "^.+$"
                  ),
                  message: "Invalid input"
                }
              })}
            />
          )}
        </Box>

        <Box sx={{ position: "relative" }} gridArea="button">
          <Button
            color="primary"
            disabled={disableButton}
            fullWidth
            aria-label="Click to continue with express pay"
            size="large"
            sx={{ maxWidth: { xs: "xs", md: "md" } }}
            type="submit"
            variant="contained"
          >
            Continue with Express Pay
          </Button>
          {disableButton && <StyledCircularProgress />}
        </Box>
      </Form>
    </>
  );
};

export default ExpressPay;
