import React from "react";
import {
  Button,
  Grid
} from "@mui/material";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Title from "../../components/Title";
import { setFlow, setShouldClearBreadcrumbs } from "../../store/auth/auth.slice";
import { REGISTERED, REGISTER_NEW_USER } from "../../utils/constants/constants";

const SuccessPage = (props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setFlow(REGISTERED));
    dispatch(setShouldClearBreadcrumbs(false));
  };

  const isRegisteredFlow = (props.flow === REGISTER_NEW_USER);

  return (
    <>
      <Title
        title={props.title}
        subTitle={props.subTitle}
        showDivider={false}
        isCentered={true}
      />
      {
        isRegisteredFlow
          ?
          <Grid container justifyContent="center">
            <Grid item xs={0} md={3} display={{ xs: "none", md: "" }} />
            <Grid item xs={12} md={6} sx={{ padding: "1.5rem 0px" }}>
              <Button
                color="primary"
                aria-label="continue to accounts page"
                component={RouterLink}
                to="/accounts"
                fullWidth
                size="large"
                variant="contained"
                type="continue"
                onClick={handleClick}
              >
              Continue
              </Button>
            </Grid>
            <Grid item xs={0} md={3} display={{ xs: "none", md: "" }} />
          </Grid>
          : null
      }
    </>
  );
};

export default SuccessPage;
