export const getPaymentConfig = (details) => {
  const maxCardAmount = details?.max_payment_amount_card || details?.max_payment_amt_card || 10000;
  const minCardAmount = details?.min_payment_amt_card || 1;

  const maxAchAmount = details?.max_payment_amt_ach || 10000;
  const minAchAmount = details?.min_payment_amt_ach || 1;

  const shouldDisableAmount = details?.only_exact_amount;

  const acceptedCardTypes = details?.accepted_card_types;

  return {
    shouldDisableAmount,
    ach: {
      maxAchAmount,
      minAchAmount
    },
    card: {
      maxCardAmount,
      minCardAmount,
      acceptedCardTypes
    }
  };

};

/**
 * @description Returns the convenience fee
 * @param { Object } details - The details config object
 * @param { Object } types - The types of convenience fees: payment or loan
 * @returns { Number } A number with the value of the desired convenience fee
 * @throws { Error } If any required keys are missing from the details or types object
 *
 */

export const getConvenienceFee = (details, types) => {
  // Constants
  const VALID_KEYS = ["convenience_fee_by_apply_to", "convenience_fee", "ach_convenience_fee", "card_convenience_fee"];
  const VALID_TYPES = ["payment", "loan"];
  const CARD = "card";
  const ACH = "ach";
  const ZERO = 0;

  // Validate keys in details and types objects
  for (const key of VALID_KEYS) {
    if (!(key in details)) {
      throw new Error(`Missing ${key} in the details object`);
    }
  }

  for (const key of VALID_TYPES) {
    if (!(key in types)) {
      throw new Error(`Missing ${key} in the types object`);
    }
  }

  // Destructure valid keys from details and types object
  const {
    convenience_fee_by_apply_to: applyToType,
    convenience_fee: defaultFee,
    ach_convenience_fee: achFee,
    card_convenience_fee: cardFee
  } = details;

  const {
    loan,
    payment
  } = types;

  // Use destructured values in function logic
  if (typeof loan === "string" && applyToType[loan]) {
    return applyToType[loan];
  }

  if (typeof payment === "string" && payment === CARD && cardFee >= 0) {
    return cardFee;
  }

  if (typeof payment === "string" && payment === ACH && achFee >= 0) {
    return achFee;
  }

  if (defaultFee >= 0) {
    return defaultFee;
  }

  return ZERO;
};

export const getAutopayConvenienceFee = (details, types) => {
  // Constants
  const VALID_KEYS = ["autopay_convenience_fee_by_apply_to", "autopay_convenience_fee"];
  const VALID_TYPES = ["loan"];
  const ZERO = 0;

  // Validate keys in details and types objects
  for (const key of VALID_KEYS) {
    if (!(key in details)) {
      console.warn(`Missing ${key} in the details object`);
    }
  }

  for (const key of VALID_TYPES) {
    if (!(key in types)) {
      console.warn(`Missing ${key} in the details object`);
    }
  }

  const autopayFeeByApplyTo = details?.autopay_convenience_fee_by_apply_to;
  const autopayFee = details?.autopay_convenience_fee;

  if (autopayFeeByApplyTo && autopayFeeByApplyTo[types.loan] >= 0) {
    return autopayFeeByApplyTo[types.loan];
  }

  if (autopayFee >= 0) {
    return autopayFee;
  }

  return ZERO;
};
