import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { PAYMENTS_TIME_ZONE } from "../constants/constants";

dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const validateIsBusinessDay = (dateToCheck) => {
  return !!(dateToCheck.day() % 6);
};

export const addBusinessDays = (daysToAdd, date) => {
  let newDate = date ? date : dayjs();
  for (let i = 0; i < daysToAdd; i++) {
    if (validateIsBusinessDay(newDate)) newDate = newDate.add(1, "day");
    while (!validateIsBusinessDay(newDate)) newDate = newDate.add(1, "day");
  }
  return newDate;
};

export const getFirstBusinessDay = (cutoffTime) => {
  // * Inclusive of today if before cutoff time
  const timeZoneDate = dayjs().tz(PAYMENTS_TIME_ZONE);
  const cutOffDate = dayjs().format(`YYYY-MM-DD [${cutoffTime}]`);

  return validateIsBusinessDay(timeZoneDate) && timeZoneDate.isBefore(dayjs(cutOffDate))
    ? dayjs().startOf("day").toDate()
    : addBusinessDays(1, timeZoneDate);
};

export const DayjsUtcLocal = () => {
  return dayjs().utc().local();
};

export const DayjsUtcLocalStr = () => {
  return dayjs().utc().local().format("YYYY/MM/DD kk:mm:ss");
};

export const DayjsUtcLocalStr_2Digit = DayjsUtcLocal().format("MM/DD/YYYY");

export const currentDate = DayjsUtcLocal().format("MMM D, YYYY");

export const dailyCutoffTimeFormat = (time) => dayjs(time, "HH").format("h:mm a");

// If no value or format is specified, today's date is returned
export const formatDateTime = (value, format) => {
  const isDateValid = dayjs(value);
  return isDateValid.isValid() && isDateValid.format(format);
};
