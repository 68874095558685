import React from "react";
import { TextField } from "@mui/material";
import { Grid, Typography } from "../../../../components/mui";
import { schema } from "../../../../utils/validators/formHookSchema";

const AccountDetails = ({
  formErrors,
  register,
  config
}) => {

  return (
    <Grid item xs={12} md={6} container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h2" noWrap>Security Questions</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField {...schema.memberId.properties(formErrors, register, config?.services?.registered?.fields )} />
      </Grid>
      {
        config?.security_question_1
          ?
          (
            <Grid item xs={12}>
              {config?.security_question_1 && <TextField {...schema.securityQuestionOne.properties(formErrors, register, config)} />}
            </Grid>
          )
          :
          null
      }
      {
        config?.security_question_2
          ?
          (
            <Grid item xs={12}>
              {config?.security_question_2 && <TextField {...schema.securityQuestionTwo.properties(formErrors, register, config)} />}
            </Grid>
          )
          :
          null
      }
    </Grid>
  );
};

export default AccountDetails;
