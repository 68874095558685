import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link } from "../../elements";

/**
 * Navigation component with arrow logo and title.
 *
 * @component
 * @param {Object} props Input props
 * @param {string} [props.title] Title of previous page to navigate back to.
 * @param {string} props.backRoute Route to navigate back to.
 * @param {function} props.handleClick Function called on click.
 * @return {JSX.Element}
 */

export const Breadcrumb = ({ title, backRoute, handleClick }) => (
  <Breadcrumbs data-cy="breadcrumb" aria-label="breadcrumb" sx={{ height: "1.5rem" }}>
    <Link
      aria-label={`Click to go back to ${title}`}
      component={RouterLink}
      onClick={handleClick}
      to={backRoute}
      underline="hover"
      sx={{ alignItems: "center", display: "flex", gap: "0.2rem" }}
    >
      <ArrowBack aria-hidden="false" aria-live="off" sx={{ verticalAlign: "middle" }} />
      {title ? `Back to ${title}` : "Go back"}
    </Link>
  </Breadcrumbs>
);
