import React, { useEffect, useState, useRef } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Tabs } from "@mui/material";
import { useDispatch } from "react-redux";
import { Alert, Box, Tab } from "../../../../components/mui";
import { resetAchVerificationState } from "../../../../store/payment/makePayment.slice";
import AchForm from "../ach/AchForm";
import CardForm from "../card/CardForm";
import TabPanel from "./TabPanel";

const TabContainer = (props) => {
  const { config, formTraits, loan, submitForm, details, paymentType } = props;
  const isCardPaymentEnabled = details?.services?.payments?.card?.enabled || details?.card_payment_enabled;
  const isAchEnabled = details?.services?.payments?.ach?.enabled || details?.ach_payment_enabled;
  const hasAchPriority = details?.services?.payments?.priority === "ach";
  const [tabIndex, setTabIndex] = useState(() => {
    if (!isCardPaymentEnabled && isAchEnabled) return 0;

    if (isCardPaymentEnabled || isAchEnabled) {
      if (paymentType === "card") return 0;
      if (paymentType === "ach" || hasAchPriority) return 1;
    }
    return 0;
  });
  const achComponent = useRef();
  const finicityRef = useRef();
  const shouldStopReRender = useRef(false);

  const isPaymentEnabled = isAchEnabled || isCardPaymentEnabled;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!achComponent.current || !finicityRef.current) return undefined;

    if (!shouldStopReRender.current) {
      achComponent.current.startFinicityFlow();
      shouldStopReRender.current = true;
    }
    return undefined;
  }, [achComponent, finicityRef]);

  const tabProps = (index) => ({
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  });

  const handleTabChange = (e, newValue) => {
    const dataId = e.target.getAttribute("data-id");
    if (dataId === "cardTab" || dataId === "cardIcon") {
      finicityRef?.current?.closeFinicityFlow();
    }
    setTabIndex(newValue);
    dispatch(resetAchVerificationState());
  };

  return (
    <Box sx={{ width: "100%", padding: 0, margins: 0 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabIndex} onChange={handleTabChange} aria-label="payment option" variant="fullWidth">
          {
            (details?.services?.payments?.card?.enabled || details?.card_payment_enabled) && (
              <Tab
                label="Card"
                icon={<CreditCardIcon data-id="cardIcon" />}
                iconPosition="start"
                {...tabProps(0)}
                aria-label="Credit Card"
                data-id="cardTab"
              />
            )
          }
          {
            (details?.services?.payments?.ach?.enabled || details?.ach_payment_enabled) && (
              <Tab
                label="Bank"
                icon={<AccountBalanceIcon data-id="bankIcon" />}
                iconPosition="start"
                {...tabProps(details?.services?.payments?.card?.enabled || details?.card_payment_enabled ? 1 : 0)}
                aria-label="Bank account"
                data-id="bankTab"
              />
            )
          }
          {
            !isPaymentEnabled && (
              <Tab
                label="Warning"
                icon={<WarningAmberIcon />}
                iconPosition="start"
                {...tabProps(0)}
                aria-label="Warning"
              />
            )
          }
        </Tabs>
      </Box>
      {
        (details?.services?.payments?.card?.enabled || details?.card_payment_enabled) && (
          <TabPanel value={tabIndex} index={0}>
            <CardForm config={config} formTraits={formTraits} loan={loan} submitForm={submitForm} />
          </TabPanel>
        )
      }
      {
        (details?.services?.payments?.ach?.enabled || details?.ach_payment_enabled) && (
          <TabPanel value={tabIndex} index={details?.services?.payments?.card?.enabled || details?.card_payment_enabled ? 1 : 0}>
            <AchForm
              formTraits={formTraits}
              loan={loan}
              submitForm={submitForm}
              ref={achComponent}
              finicityRef={finicityRef}
            />
          </TabPanel>
        )
      }

      {
        !isPaymentEnabled && (
          <TabPanel value={tabIndex} index={0}>
            <Alert
              severity="warning"
              message="Payments not enabled"
              icon={false}
            />
          </TabPanel>
        )
      }
    </Box>
  );
};

export default TabContainer;
