import React, { useEffect, useState } from "react";
import { FormControl, FormHelperText, Grid, InputLabel, Select, TextField } from "@mui/material";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "../../../../../components/mui/features/DatePicker";
import { setPaymentDate } from "../../../../../store/payment/makePayment.slice";
import { getSavedAccounts } from "../../../../../store/savedAccounts/savedAccounts.slice";
import { REGISTERED, REGISTER_NEW_USER } from "../../../../../utils/constants/constants";
import { cardFormValidators } from "../../../../../utils/validators/CardFormValidators";
import { schema } from "../../../../../utils/validators/formHookSchema";

const RegisteredDisplay = ({
  control,
  config,
  register,
  errors,
  maxPostDays,
  setError,
  nameOnAccount,
  setValue
}) => {
  // * Named selectors
  const authState = (state) => state.auth;
  const savedAccountsState = (state) => state.savedAccounts;
  const paymentState = (state) => state.payment;

  // * Redux state
  const { authToken, flow } = useSelector(authState);
  const { getAccounts } = useSelector(savedAccountsState);
  const { paymentDetails } = useSelector(paymentState);

  // * Hooks
  const [validDates, setValidDates] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const shouldCheckSavedAccounts = (flow === REGISTERED || flow === REGISTER_NEW_USER);
    if (getAccounts?.response?.found === undefined && shouldCheckSavedAccounts) {
      dispatch(getSavedAccounts(authToken.value));
    }
  }, [getAccounts?.response?.found, authToken.value, dispatch, flow]);

  const formatAccount = (account) => {
    return `Account ending in ${account?.last_4} with ${account?.banking_details?.name_of_bank}`;
  };

  const nameOnAccountOptions = {
    required: "Account name is required",
    pattern: {
      value: cardFormValidators.fullName.value,
      message: cardFormValidators.fullName.message
    }
  };

  const handleDate = (date) => {
    date = dayjs(date).format("MM/DD/YYYY");
    setValue("date", date);
    dispatch(setPaymentDate(date));
  };

  return (
    <>

      <Grid item xs={12}>
        <FormControl fullWidth component="fieldset" error={!!(errors?.payFromSavedAccount?.message)}>
          <InputLabel htmlFor="account-selection" id="account-label" sx={{ zIndex: 0 }}>Account</InputLabel>
          <Controller
            name="payFromSavedAccount"
            control={control}
            rules={{ required: "Account is required" }}
            render={({ field }) => {
              return (
                <Select
                  disabled={getAccounts?.response?.results?.length === 0}
                  fullWidth
                  inputProps={{ id: "account-selection", "aria-labelledby": "account-label" }}
                  label="Account"
                  labelId="account-label"
                  native
                  {...field}
                >
                  <option value="" disabled ></option>
                  {getAccounts?.response?.results.map((account) => (
                    <option value={account.id} key={account.id} >{formatAccount(account)}</option>
                  ))}
                </Select>
              );
            }}
          />
          <FormHelperText>{errors?.payFromSavedAccount?.message || " "}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("nameOnAccount", nameOnAccountOptions)}
          {...schema.defaultProperties("nameOnAccount")}
          autoComplete="off"
          value={nameOnAccount || ""}
          error={!!errors?.nameOnAccount}
          helperText={errors?.nameOnAccount ? errors.nameOnAccount.message : " "}
          id="nameOnAccount-input"
          label="Name on Account"
          sx={{ zIndex: 0 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="date"
          control={control}
          rules={{
            required: "Date is required",
            validate: (value) => {
              const isValidDay = validDates?.includes(dayjs(value).format("MM/DD/YYYY"));
              return isValidDay ? null : "Please choose an available date";
            }
          }}
          render={({
            field: {
              value,
              ...additionalProps
            },
            fieldState: {
              error
            }
          }) => (
            <DatePicker
              additionProps={{ ...additionalProps }}
              value={paymentDetails?.date ? paymentDetails.date : value}
              onChange={handleDate}
              error={error}
              config={config}
              maxPostDays={maxPostDays}
              setError={setError}
              setValidDates={setValidDates}
              validDates={validDates}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default RegisteredDisplay;
