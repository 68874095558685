import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Alert, Container, Typography } from "../components/mui";
import { StyledCircularProgress } from "../components/styled/StyledCircularProgress";
import { Main, Navbar } from "../containers";
import MaintenanceWindow from "../pages/MaintenanceWindow/MaintenanceWindowPage";
import RouterConfig from "../routes";
import { useSDK } from "../services/sdk/hooks/useSDK";
import { MAINTENANCE_TITLE } from "../utils/constants/constants";

export const Layout = ({
  isSiteLoading,
  loadingMessage,
  redirectionPath,
  shouldClearBreadcrumbs,
  timeoutMessage
}) => {

  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const maintenanceState = (state) => state.maintenance;
  const paymentState = (state) => state.payment;

  const { loan } = useSelector(paymentState);
  const { authToken, user, flow } = useSelector(authState);
  const { config, details, detailsLoaded } = useSelector(institutionState);
  const { message, isMaintenanceWindowActive } = useSelector(maintenanceState);

  // Styles
  const containerStyles = {
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 2px 3px -3px rgba(0, 0, 0, 0.2), 0px 2px 3px -3px rgba(0, 0, 0, 0.2), 0px 3px 8px 2px rgba(0, 0, 0, 0.10)",
    maxHeight: "100%",
    width: "100%",
    maxWidth: {
      md: "md",
      sm: "sm",
      xs: "xs"
    },
    outline: "none",
    display: "flex",
    flexDirection: "column",
    height: isSiteLoading ? "46.875rem" : null
  };

  const enhancedExperience = details?.services?.sdk?.enhanced;

  const { start, close, isRouteAllowed } = useSDK({
    "token": authToken.value,
    "initialization": {
      "ACHWebVerificationAccountId": `${details?.ach_web_verification_id}`
    },
    "payload": {
      "experience": "VERIFY_ACH_ENHANCED_SINGLE_ACCOUNT",
      "ACHWebVerificationAccountId": `${details?.ach_web_verification_id}`,
      "user": {
        "first_name": user.first_name,
        "last_name": user.last_name,
        "username": user.email_address
      }
    }
  });

  useEffect(() => {
    if (details && isRouteAllowed && enhancedExperience) {
      start();
      return () => close();
    }
  }, [details, isRouteAllowed, enhancedExperience]);

  return (
    <Container sx={containerStyles} tabIndex="-1" disableGutters>
      {
        isSiteLoading
          ?
          <div style={{ boxSizing: "border-box", margin: "auto", display: "flex", flexDirection: "column" }}>
            <Typography variant="h1" sx={{ textAlign: "center" }}>
              <output aria-live="assertive">{loadingMessage}</output>
            </Typography>
            <StyledCircularProgress size="4rem" sx={{ position: "relative" }} />
          </div>
          :
          <>
            <Navbar
              authToken={authToken.value}
              config={config}
              detailsLoaded={detailsLoaded}
              flow={flow}
              isMaintenanceWindowActive={isMaintenanceWindowActive}
              location={location}
              redirectionPath={redirectionPath}
            />
            <Main>
              <Alert message={timeoutMessage} severity="error" />
              {
                isMaintenanceWindowActive
                  ? <MaintenanceWindow config={config} title={MAINTENANCE_TITLE} subtitle={message} />
                  : <RouterConfig
                    authToken={authToken.value}
                    config={config}
                    loan={loan}
                    redirectionPath={redirectionPath}
                    shouldClearBreadcrumbs={shouldClearBreadcrumbs}
                  />
              }
              {
                enhancedExperience
                  ? <div id="enhanced-ach-experience" tabIndex="-1" />
                  : null
              }
            </Main>
          </>
      }
    </Container>
  );
};
