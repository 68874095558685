import React from "react";
import { TextField } from "@mui/material";
import { Box, Grid, Typography } from "../../../../components/mui";
import { schema } from "../../../../utils/validators/formHookSchema";

const UserInfo = ({
  formErrors,
  register,
  reduxForm,
  dispatch
}) => {
  return (
    <Grid
      item
      xs={12}
      md={6}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12}>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h2">User Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField {...schema.firstName.properties(formErrors, register)} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...schema.lastName.properties(formErrors, register)} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...schema.email.properties(formErrors, register, reduxForm?.emailAddress, dispatch)} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UserInfo;
