import React from "react";
import { Alert as MUIAlert } from "@mui/material";

/**
 * Wrapper component for Material UI Alert
 *
 * @component
 * @param {object} props Props defined by Material UI. Please see Material UI documentation for
 * Alert (https://mui.com/material-ui/api/alert/) and Paper (https://mui.com/material-ui/api/paper/)
 * for details.
 * @param {any} props.message Specific message for alert. Passed in via props for ease of accessibility.
 * @return {JSX.Element}
 */

export const Alert = (props) => {
  const consistentStyle = {
    display: props.message ? "inline-flex" : "none",
    marginBottom: "1rem",
    ...props.sx
  };

  const disableIcon = props?.disableIcon ? { icon: false } : undefined;

  return (
    <MUIAlert
      data-cy="alert"
      sx={consistentStyle}
      severity={props?.severity || "error"}
      {...disableIcon}
    >
      {props.message ? props.message : undefined}
      {props.children ? props.children : undefined}
    </MUIAlert>
  );
};
