import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Menu,
  Toolbar,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Box, CardMedia, Container, Breadcrumb, ExitSessionButton, IconButton, MenuItem, Link as MUILink } from "../../components";
import { StyledLink } from "../../components/styled/StyledLink";
import { clearAuthStateOnLogout } from "../../store/auth/auth.slice";
import { handleBackNavigation } from "../../store/navhistory/navhistory.slice";
import { REGISTERED, NO_AUTH_LOCATIONS, REGISTER_NEW_USER } from "../../utils/constants/constants";
import { swivelColors } from "../../utils/data/swivelColors";
import { useMaterialTheme } from "../../utils/hooks/useMaterialTheme";

// Routes
const pages = ["Accounts", "History"];
const settings = ["Profile"];
const manuallyLinkAccount = ["/autopay/edit", "/autopay/setup", "/make-payment"];

export const Navbar = ({ authToken, config, detailsLoaded, flow, isMaintenanceWindowActive, redirectionPath }) => {
  // Named selectors
  const navHistoryState = (state) => state.navHistory;

  // Pass in named selectors and gets state from redux
  const { previousLocations } = useSelector(navHistoryState);

  // Local State
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [backRoute, setBackRoute] = useState("");
  const [title, setTitle] = useState("");

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const materialTheme = useMaterialTheme();
  const mediaQueryBreakpoints = useMediaQuery(materialTheme.breakpoints.up("md"));
  const { swivelize } = useFlags();

  const checkWindow = window.top !== window.self;
  const isUnprotectedPage = NO_AUTH_LOCATIONS.find(path => location.pathname === path);
  const isFlowValid = (flow === REGISTERED) || (flow === REGISTER_NEW_USER);

  useEffect(() => {
    let pastObj;
    if (previousLocations.length > 0) {
      pastObj = previousLocations[previousLocations.length - 1];
    }
    if (manuallyLinkAccount?.includes(location.pathname)) {
      setTitle("Accounts");
      setBackRoute("/accounts");
    } else {
      setTitle(pastObj?.title);
      setBackRoute(pastObj?.params ? pastObj?.route + pastObj?.params : pastObj?.route);
    }
  }, [previousLocations, location]);

  useEffect(() => {
    if (mediaQueryBreakpoints && anchorElNav) {
      handleCloseNavMenu();
    }
  }, [mediaQueryBreakpoints, anchorElNav]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (route) => {
    setAnchorElUser(null);
    navigate(route);
  };

  const handleBackNavigationClick = () => {
    dispatch(handleBackNavigation());
  };

  const exitSessionCallback = () => {
    if (checkWindow) {
      const origins = "*";
      window.parent.postMessage("closeIframe", origins);
    }
    handleCloseUserMenu(redirectionPath);
    dispatch(clearAuthStateOnLogout());
  };

  return (
    <AppBar color="transparent" elevation={0} position="static">
      <Container>
        <Toolbar sx={{ display: "flex", height: "64px", justifyContent: "space-between" }} disableGutters>
          {/* Hamburger Menu */}
          {
            authToken && detailsLoaded && isFlowValid && !isUnprotectedPage
              ? <>
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-controls="pages-menu-appbar"
                    aria-label="account of current user"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>

                  <Menu
                    id="pages-menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    keepMounted
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{ display: { xs: "block", md: "none" } }}
                  >
                    {pages.map((page) => (
                      <MenuItem
                        arial-label={`Click to go to ${page}`}
                        key={page}
                        onClick={() => handleCloseNavMenu(`/${page.toLocaleLowerCase()}`)}
                      >
                        <MUILink component={Link} to={`/${page.toLocaleLowerCase()}`} underline="hover" >
                          {page}
                        </MUILink>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              </>
              : null

          }

          {/* Logo */}
          {
            config && !isMaintenanceWindowActive
              ? <Link arial-label="Click to go to Home" className="logo-container" to={flow && !isUnprotectedPage ? "/accounts" : redirectionPath} >
                <CardMedia name={config?.brand_name} url={swivelize ? "" : config?.brand_logo_url} />
              </Link>
              : <CardMedia name={config?.brand_name} url={swivelize ? "" : config?.brand_logo_url} centerLogo={true} />
          }

          {
            authToken && detailsLoaded && flow && !isUnprotectedPage
              ? <>
                {/* Nav Links on desktop breakpoint */}
                {
                  isFlowValid
                    ?
                    <Box sx={{
                      flexGrow: 1,
                      display: { xs: "none", md: "flex" },
                      alignItems: "center",
                      gap: "1rem",
                      justifyContent: "flex-end",
                      marginRight: "1.5rem"
                    }}
                    >
                      {pages.map((page) => (
                        <StyledLink
                          config={swivelize ? swivelColors : config}
                          key={page}
                          arial-label={`Click to go to ${page}`}
                          onClick={handleCloseNavMenu}
                          to={`/${page.toLocaleLowerCase()}`}
                        >
                          {page}
                        </StyledLink>

                      ))}
                    </Box>
                    : null
                }

                {/* User Menu */}
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <>
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, size: "2rem" }}>
                        <Avatar alt="Name" src="" />
                      </IconButton>
                    </>
                  </Tooltip>

                  <Menu
                    sx={{ mt: "42px" }}
                    id="settings-menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    keepMounted
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {
                      isFlowValid
                        ?
                        settings.map((setting) => (
                          <MenuItem
                            arial-label={`Click to go to ${setting}`}
                            key={setting}
                            onClick={() => handleCloseUserMenu(`/${setting.toLocaleLowerCase()}`)}
                          >
                            <MUILink component={Link} to={`/${setting.toLocaleLowerCase()}`} underline="hover" >
                              {setting}
                            </MUILink>
                          </MenuItem>
                        ))
                        :
                        null
                    }

                    {/* Default always show logout */}
                    <MenuItem arial-label="Click to go to Logout" onClick={exitSessionCallback}>
                      <MUILink component={Link} to={redirectionPath} underline="hover">Logout</MUILink>
                    </MenuItem>
                  </Menu>
                </Box>
              </> : null
          }

          {/* Displays Exit Button within iframe */}
          {
            !authToken && !flow && checkWindow
              ? <ExitSessionButton
                aria="Click to exit"
                closeFunc={exitSessionCallback}
                iconOnly={true}
              /> : null
          }
        </Toolbar>
        {
          backRoute
            ? <Breadcrumb backRoute={backRoute} title={title} handleClick={handleBackNavigationClick} />
            : <div style={{ height: "1.5rem" }} />
        }
      </Container>
    </AppBar >
  );
};
