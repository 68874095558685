import React, { useState } from "react";
import { FormControl, TextField, useMediaQuery } from "@mui/material";
import { DatePicker, MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formatDateTime } from "../../../../utils/helpers/dayjsHelpers";
import { useMaterialTheme } from "../../../../utils/hooks/useMaterialTheme";

export const Calendar = (props) => {

  // Backup state if value isn't passed to component
  const [defaultDate, setDefaultDate] = useState("");

  // Hooks
  const materialTheme = useMaterialTheme();
  const mediaQueryBreakpoints = useMediaQuery(materialTheme.breakpoints.up("md"));

  // Defaults for props
  const defaultDateFormat = "MM/DD/YYYY";
  const dateFormat = props?.dateFormat || defaultDateFormat;
  const label = props?.label || "Date";
  const required = props?.required || true;

  // Props passed into TextField
  const textfieldProps = {
    required,
    error: !!props?.error || !!props?.errors,
    helperText: props?.helperText || props?.errors?.message || dateFormat
  };

  // Props passed into DatePicker & MobileDatePicker
  const datePickerProps = {
    allowKeyboardControl: true,
    dateFormat,
    inputProps: { "aria-required": "true" },
    label,
    onChange: props?.onChange || ((event) => setDefaultDate(event)),
    OpenPickerButtonProps: { "aria-label": "Click to open date picker", "data-cy": "date-picker-button" },
    renderInput: (renderProps) => <TextField sx={{ zIndex: 0 }} {...renderProps} {...textfieldProps} />,
    value: props?.value
      ? formatDateTime(props?.value, dateFormat)
      : formatDateTime(defaultDate, dateFormat),
    ...props
  };

  return (
    <FormControl {...props.control} {...required}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        {
          mediaQueryBreakpoints
            ? (<DatePicker {...datePickerProps} />)
            : (<MobileDatePicker {...datePickerProps} />)
        }
      </LocalizationProvider>
    </FormControl>
  );
};
