export const routeMap = new Map([
  ["/express-pay", { title: "Express Pay", route: "/express-pay" }],
  ["/accounts", { title: "Accounts", route: "/accounts" }],
  ["/make-payment", { title: "Make A Payment", route: "/make-payment" }],
  ["/review-payment", { title: "Review Payment", route: "/review-payment" }],
  ["/pending-payments", { title: "Pending Payments", route: "/pending-payments" }],
  ["/payment-confirmed", { title: "Payment Confirmed", route: "/payment-confirmed" }],
  ["/link-bank-account", { title: "Link Bank Account", route: "/link-bank-account" }],
  ["/login", { title: "Login", route: "/login" }],
  ["/register", { title: "Register", route: "/register" }],
  ["/register/guest", { title: "Register Guest", route: "/register/guest" }],
  ["/non-validation/create-password", { title: "Create Password", route: "/non-validation/create-password" }],
  ["/create-password", { title: "Create Password", route: "/create-password" }],
  ["/gate/reset-password", { title: "Create Password", route: "/gate/reset-password" }],
  ["/check-email", { title: "Check Your Email", route: "/check-email" }],
  ["/success/registration", { title: "Successful Registration", route: "/success/registration" }],
  ["/success/password-reset", { title: "Successful Password Reset", route: "/success/password-reset" }],
  ["/history", { title: "History", route: "/history" }],
  ["/profile", { title: "Profile", route: "/profile" }],
  ["/profile/password", { title: "Change Password", route: "/profile/password" }],
  ["/profile/email", { title: "Change Email", route: "/profile/email" }],
  ["/profile/state-of-residence", { title: "Change State of Residence", route: "/profile/state-of-residence" }],
  ["/reset-password", { title: "Reset Your Password", route: "/reset-password" }],
  ["/autopay/authorize", { title: "Autopay Schedule", route: "/autopay/authorize" }],
  ["/autopay/edit", { title: "Edit Autopay Schedule", route: "/autopay/edit" }],
  ["/autopay/setup", { title: "Setup Autopay Schedule", route: "/autopay/setup" }],
  ["/connect-bank", { title: "Connect your bank", route: "/connect-bank" }]
]);
