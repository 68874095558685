import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import { SDKContextProvider } from "./services/sdk/context/SDKProvider";
import { persistor, store } from "./store";

if (navigator.doNotTrack !== "1") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_WEB_DATA_STREAM, {
    gaOptions: {
      cookieFlags: "max-age=7200;samesite=none;secure"
    }
  });
}

const queryClient = new QueryClient();
const renderApp = async () => {

  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    options: {
      streaming: true
    }
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <LaunchDarklyProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <SDKContextProvider>
                  <App />
                </SDKContextProvider>
              </QueryClientProvider>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </LaunchDarklyProvider>
    </React.StrictMode>
  );
};

renderApp();
