import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Alert, Grid, Modal } from "../../components";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import { StyledDivider } from "../../components/styled/StyledDivider";
import Title from "../../components/Title";
import { getLoans } from "../../store/loans/loans.slice";
import { getIdempotencyToken, getPaymentHistory, setLoan, setLoanPaymentType } from "../../store/payment/makePayment.slice";
import { useViewPort } from "../../utils/hooks/useViewPort";
import PaymentHistoryCard from "./components/leftSide/PaymentHistoryCard";
import PaymentSummary from "./components/rightSide/PaymentSummary";

const PaymentHistory = () => {
  // * Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const paymentState = (state) => state.payment;
  const loanState = (state) => state.loans;

  // * Use named selectors
  const { authToken } = useSelector(authState);
  const { history } = useSelector(paymentState);
  const reduxLoanState = useSelector(loanState);
  const { config, details } = useSelector(institutionState);
  const viewPort = useViewPort();

  // * Hooks
  const dispatch = useDispatch();
  const [paymentToDisplay, setPaymentToDisplay] = useState(null);
  const [payToAccount, setPayToAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [transactionHistory, setTransactionHistory] = useState({});
  const [sortOption, setSortOption] = useState("Date");

  const pageRef = useRef();
  const summaryRef = useRef();
  const navigate = useNavigate();

  const historyResults = history?.response?.results;
  const historyLength = historyResults?.length;
  const { current } = pageRef;

  useEffect(() => {
    if (current) { current.focus(); }
  }, [ current ]);

  useEffect(() => {
    if (!dispatch || !authToken.value) return undefined;
    if (history.isLoading || reduxLoanState.isLoading) return undefined;

    if (!reduxLoanState.list) {
      dispatch(getLoans(authToken.value));
    }

    dispatch(getPaymentHistory(authToken.value));
    return undefined;
  }, []);

  useEffect(() => {
    if (historyResults && historyLength > 0) {
      setPaymentToDisplay(historyResults[0]);
    }
  }, [ historyResults, historyLength ]);

  useEffect(() => {
    const loanInfo = reduxLoanState.list?.find(loan => loan.id === paymentToDisplay?.loan_id);
    setPayToAccount(loanInfo);
  }, [ paymentToDisplay, reduxLoanState.list ]);

  useEffect(()=> {
    if (historyResults?.length && reduxLoanState?.list?.length) {
      const result = [];

      historyResults.forEach((transaction) => {
        const matched = reduxLoanState?.list?.find(loan => loan.id === transaction.loan_id);
        if (matched) {
          result.push({ ...transaction, ...{
            transaction_type: matched?.type,
            next_amount_due: matched?.next_due_date
          } });
        }
      });

      const Date = [ ...result ].sort((a, b) => (dayjs(a.transaction_date).isAfter(dayjs(b.transaction_date)) ? 1 : -1)).reverse();
      const Amount = [ ...result ].sort((a, b) => a.amount - b.amount);
      const Account = [ ...result ]
        .sort((a, b) => a.transaction_type.localeCompare(b.transaction_type))
        .sort((a, b) => (dayjs(a.transaction_date).isAfter(dayjs(b.transaction_date)) ? 1 : -1));

      setTransactionHistory({ Date, Amount, Account });
    }
  }, [ historyResults, reduxLoanState.list, historyLength ]);

  const openModalIfMobile = () => {
    if (!viewPort.medium) {
      setIsModalOpen(true);
    }
  };

  const focusOnSummary = () => {
    summaryRef?.current?.focus();
  };

  const payNow = () => {
    dispatch(getIdempotencyToken(authToken.value))
      .unwrap()
      .then((res) => {
        const details = { ...payToAccount, idempotencyToken: res.requestToken };
        dispatch(setLoan(details));
        dispatch(setLoanPaymentType(details?.type));
        navigate("/make-payment");
      })
      .catch(() => {
        setErrorMessage("Unable to obtain payment details, please try again");
      });
  };

  const sortOptions = [ "Date", "Account", "Amount" ];

  // * Return values
  if (history.isLoading || reduxLoanState.isLoading) {
    return (
      <Box role="group">
        <Box sx={{ position: "relative", height: "5rem" }}>
          <StyledCircularProgress size={"2.5rem"} />
        </Box>
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          <output aria-live="assertive">Loading payment history...</output>
        </Typography>
      </Box>
    );
  }

  if (history.error) {
    return <Alert message="Transaction history is not available" severity="warning" />;
  }

  if (history?.response?.results?.length === 0) {
    return <Alert message="No transaction history found" severity="info" />;
  }

  return (
    <div ref={pageRef} tabIndex="-1" style={{ outline: "none", ":focus": { outline: "none" } }}>
      <Title title="History" />
      <Alert message={errorMessage ? errorMessage : undefined} severity="error" />
      <Grid container spacing={3} sx={{ height: "47rem", maxHeight: "47rem" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ height: "100%", overflowY: "scroll", "::-webkit-scrollbar": { display: "none" } }}
        >
          <Grid container component={"aside"}>
            <Grid item xs={12} container>
              <Grid item xs={8}>
                <Typography variant="h2" noWrap>Recent Transactions</Typography>
              </Grid>
              <Grid item xs={4} sx={{ marginBottom: "1rem" }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="sort-option" id="sort-option-label">Sort</InputLabel>
                  <Select
                    fullWidth
                    label="Sort"
                    labelId="sort-option-label"
                    name="sort transactions"
                    native
                    onChange={(e) => setSortOption(e.target.value)}
                    value={sortOption}
                    inputProps={{ id: "sort-option", "aria-labelledby": "sort-option-label" }}
                  >
                    {sortOptions.map((option, i) => (
                      <option value={option} key={i}>{option}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StyledDivider style={{ marginBottom: "0" }}/>
            </Grid>

            {transactionHistory[sortOption]?.map((record, i) => (
              <Grid item xs={12} key={i}>
                <PaymentHistoryCard
                  data={record}
                  key={i}
                  setPaymentToDisplay={setPaymentToDisplay}
                  focusOnSummary={focusOnSummary}
                  setErrorMessage={setErrorMessage}
                  openModalIfMobile={openModalIfMobile}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={0}
          md={6}
          id="summary-container"
          sx={{ display: { xs: "none", md: "block" }, height: "100%" }}
        >
          <PaymentSummary
            payment={paymentToDisplay}
            institutionDetails={{ ...config, ...details }}
            payToAccount={payToAccount}
            isDesktop={true}
            ref={summaryRef}
            payNow={payNow}
          />
        </Grid>
      </Grid>

      <Modal
        open={isModalOpen}
        close={() => setIsModalOpen(false)}
        messages={{ title: undefined, content: undefined }}
        sx={{ padding: ".9rem", margin: ".1rem" }}
        buttons={{
          primary: {
            children: "Pay now",
            onClick: () => payNow()
          },
          secondary: {
            children: "Close",
            onClick: () => setIsModalOpen(false)
          }
        }}
      >
        <PaymentSummary
          payment={paymentToDisplay}
          institutionDetails={{ ...config, ...details }}
          payToAccount={payToAccount}
        />
      </Modal>
    </div>
  );
};

export default PaymentHistory;
